import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  postRequestAuthenticatedWithFile,
  postRequestAuthenticated,
} from "./../../services/rest";

export const createChatEvent = createAsyncThunk(
  "event/createChatEvent",
  async (data, { rejectWithValue }) => {
    try {
      const response = await postRequestAuthenticatedWithFile(
        "/create/chat/event",
        data
      );
      if (response.success) {
        return response.chat;
      } else {
        return rejectWithValue(response.message);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const joinEvent = createAsyncThunk(
  "event/joinEvent",
  async (eventId, { rejectWithValue }) => {
    try {
      const response = await postRequestAuthenticated("/join/event", {
        eventId,
      });
      if (response.success) {
        return response.event;
      } else {
        return rejectWithValue(response.message);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  name: "",
  topic: "",
  description: "",
  startDate: "",
  endDate: "",
  startTime: "",
  endTime: "",
  locationText: "",
  timezone: "Asia/Kolkata",
  location: "",
  joining: "public",
  cover_image: "",
  cover_image_source: "",
};

const eventSlice = createSlice({
  name: "event",
  initialState,
  reducers: {
    setEventField: (state, action) => {
      const { field, value } = action.payload;
      state[field] = value;
    },
    clearEvent: (state, action) => {
      return { ...initialState };
    },
  },
});

export const { setEventField, clearEvent } = eventSlice.actions;

export default eventSlice.reducer;
